import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Tag } from '@core/shared/data-access';
import { wrapInImageFormData } from '@core/shared/util';

import { Profil, UpdateProfil } from './profil';

@Injectable({ providedIn: 'root' })
export class ProfilService {
  private readonly baseApiPath = 'api/me';

  constructor(private readonly http: HttpClient) {}

  getProfil(): Observable<Profil> {
    return this.http.get<Profil>(`${this.baseApiPath}`);
  }

  update(updateDto: UpdateProfil): Observable<Profil> {
    const { bild, ...rest } = updateDto;
    const formData: FormData = wrapInImageFormData(bild, 'bild', rest);
    return this.http.put<Profil>(`${this.baseApiPath}`, formData);
  }

  switchOrganisation(organisationId: string): Observable<Tag> {
    return this.http.put<Tag>(`${this.baseApiPath}`, { activeOrganisation: organisationId });
  }

  changePassword(passwort: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseApiPath}/reset`, { passwort });
  }
}
